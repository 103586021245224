@import url('https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:ital,wght@0,400;0,700;1,400&display=swap');

.wrapper {
  width: 100%;
  height: 100vh;
  position: relative;
  display: inline-block;
  font-family: 'Libre Caslon Text', serif;
  font-size: 16px;
  color: #dedede;
  text-align: center;
  background-color: #141414;
  overflow: scroll;
}

.shooting-star {
  position: fixed;
}

.content {
  width: 100%;
  max-width: 1200px;
  position: relative;
  display: inline-block;
  padding: 80px 2%;
  box-sizing: border-box;
}

.bg {
  width: 50%;
  max-width: 800px;
  position: fixed;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('./img/bm.svg');
  background-size: cover;
}

.bg::before {
  content: '';
  display: block;
  padding-top: 73%;
  position: relative;
}

h2.welcome {
  position: relative;
  font-size: 1.55rem;
  font-weight: 100;
  margin: 10px 0;
}

h2.welcome span {
  font-style: italic;
}

.profile-image {
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  margin-top: 40px;
  background-image: url('./img/bungo.jpg');
  background-size: cover;
  border-radius: 50%;
}

h2.name {
  position: relative;
  font-size: 1.85rem;
  font-weight: 100;
  margin: 10px 0;
}

.slider {
  width: 100%;
  max-width: 400px;
  position: relative;
  display: inline-block;
}

h3.title {
  width: 100%;
  position: relative;
  font-size: 1.25rem;
  font-weight: 100;
  font-style: italic;
  line-height: 1.5;
  margin: 0;
}

.socials {
  width: 100%;
  position: relative;
  display: inline-block;
  margin: 40px 0 10px;
}

.instagram, .twitter, .email {
  position: relative;
  display: inline-block;
  margin: 0 10px;
}

.portfolio {
  width: 100%;
  max-width: 400px;
  position: relative;
  display: inline-block;
  margin: 40px 0 0;
}

a.p-link {
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid #dedede;
  text-decoration: none;
}

a.p-link .p-img {
  width: 40px;
  height: 40px;
  margin: 5px 0;
  position: relative;
  display: block;
  float: left;
  background-color: #fff;
}

a.p-link .p-img img {
  max-width: 100%;
  position: relative;
}

a.p-link p.p-name {
  position: relative;
  display: block;
  float: left;
  margin: 0 0 0 10px;
  font-size: .85rem;
  font-weight: 100;
  line-height: 4;
  color: #dedede;
}

.arrow-right {
  position: relative;
  display: block;
  float: right;
  margin: 20px 0 20px;
}